<template>
	<b-modal
		id="modal-lg modal-scrollable"
		ref="my-modal"
		v-model="isOpen"
		scrollable
		title="Category Details"
		hide-footer
		size="lg"
		@hide="hideModal"
	>
		<div v-if="category">
			<div
				v-for="subCategory in category.subCategories"
				:key="subCategory.productSubCategoryNameEng + Math.random()"
				class="p-1 rounded border mb-2"
			>
				<div class="d-flex justify-content-between">
					<div>
						<p>
							Title:
							<b>{{
								`${subCategory.productSubCategoryNameGeo}  / ${subCategory.productSubCategoryNameEng}`
							}}</b>
						</p>
						<p>
							Service type:
							<b>{{ serviceTypeList[subCategory.serviceType] }}</b>
						</p>
					</div>
					<div>
						<b-button
							variant="flat-danger"
							class="btn-icon"
							@click="
								deleteCategory('subCategory', subCategory.productSubCategoryId)
							"
						>
							<feather-icon size="16" icon="TrashIcon" />
						</b-button>
					</div>
				</div>
				<label v-if="subCategory.genericCategories.length">
					Generic Categories of this SubCategory:
				</label>
				<div
					v-for="genericCategory in subCategory.genericCategories"
					:key="genericCategory.productGenericCategoryNameGeo + Math.random()"
					class="p-1 mb-1 border rounded-sm d-flex justify-content-between"
				>
					<div>
						<div>
							Title:
							<b>{{
								`${genericCategory.productGenericCategoryNameGeo}  / ${genericCategory.productGenericCategoryNameEng}`
							}}</b>
						</div>
						<div>
							GiftType: <b> {{ GiftTypeList[genericCategory.giftType] }}</b>
						</div>
						<div>
							Percentage:<b> {{ genericCategory.emotyCommissionFeePercent }}</b>
						</div>
					</div>
					<div>
						<b-button
							variant="flat-danger"
							class="btn-icon"
							@click="
								deleteCategory(
									'genericCategory',
									genericCategory.productGenericCategoryId
								)
							"
						>
							<feather-icon size="16" icon="TrashIcon" />
						</b-button>
					</div>
				</div>
			</div>
			<div class="buttons-wrapper">
				<b-button variant="primary" class="mb-2 mt-2" @click="hideModal">
					Close
				</b-button>
			</div>
		</div>
	</b-modal>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css';
import { BButton } from 'bootstrap-vue';
import { mapActions, mapMutations } from 'vuex';

export default {
	components: {
		BButton,
	},
	props: {
		isOpen: Boolean,
		category: {
			type: Object,
			default: () => {},
		},
		deleteCategory: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			serviceTypeList: {
				1: 'Experimental',
				2: 'Material',
				3: 'Digital',
				4: 'Card',
			},
			GiftTypeList: {
				1: 'Both',
				2: 'Daily',
				3: 'Holiday',
			},
		};
	},
	methods: {
		...mapActions('parametersModule', ['getCategory']),
		...mapMutations(['CLEAR_CATEGORY']),

		hideModal() {
			this.$refs['my-modal'].hide();
			this.$store.commit('parametersModule/CLEAR_CATEGORY');
			this.$emit('hide', false);
		},
	},
};
</script>

<style lang="scss" scoped>
.card-body {
	display: flex;
}
</style>
