<template>
	<b-card>
		<b-button
			class="city-button mb-2"
			variant="primary"
			size="sm"
			to="/add-category"
		>
			<feather-icon icon="PlusIcon" class="mr-25" />
			<span>Create Category</span>
		</b-button>
		<CategoryInfoPopup
			:is-open="popupIsOpen"
			:category="catForPopup"
			:delete-category="deleteCategory"
			@hide="(e) => (popupIsOpen = e)"
		/>
		<div class="scrollme">
			<b-table
				hover
				:items="categoriesList"
				:fields="fields"
				responsive
				bordered
				primary-key="id"
				show-empty
				empty-text="No matching records found"
				small
				class="shadow-sm rounded"
				@row-clicked="(e) => onClickCategoryRow(e)"
			>
				<template #cell(actions)="data">
					<b-button
						variant="flat-danger"
						class="btn-icon p-0"
						@click="deleteCategory('category', data.item.productCategoryId)"
					>
						<feather-icon size="16" icon="TrashIcon" />
					</b-button>
				</template>
			</b-table>
		</div>
	</b-card>
</template>

<script>
import { BCard, BTable, BButton } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import Swal from 'sweetalert2';
import CategoryInfoPopup from './CategoryInfoPopup.vue';

export default {
	components: {
		BCard,
		BTable,
		CategoryInfoPopup,
		BButton,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent,
	},
	data() {
		return {
			catForPopup: null,
			popupIsOpen: false,
			createPopUpIsOpen: false,
			categoriesList: [],
			fields: [
				{
					key: 'productCategoryId',
					label: 'ID',
				},
				{
					key: 'productCategoryNameEng',
					label: 'Category Name',
					formatter: (value, key, item) =>
						`${item.productCategoryNameGeo} / ${item.productCategoryNameEng}`,
				},
				{
					key: 'subCategories.length',
					label: 'Number of Sub Categories',
				},
				{
					key: 'actions',
					label: 'actions',
				},
			],
		};
	},
	computed: {
		...mapState('parametersModule', ['categories', 'category']),
	},
	created() {
		this.getCategories().then(() => {
			this.categoriesList = this.categories.data.productCategories;
		});
	},
	methods: {
		...mapActions('parametersModule', [
			'getCategories',
			'getCategory',
			'deleteAnyCategory',
		]),
		deleteCategory(type, id) {
			const payload = { type, id };
			console.log(payload);
			Swal.fire({
				title: 'Are you sure?',
				text: "You won't be able to revert this!",
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#28a745',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, Accept it!',
			}).then((result) => {
				if (result.isConfirmed) {
					this.deleteAnyCategory(payload).then(() => {
						this.$toast({
							component: ToastificationContent,
							props: {
								title: 'Category sucessfully deleted',
								icon: 'FileIcon',
								variant: 'success',
							},
						});
						this.getCategories();
						this.popupIsOpen = false;
					});
				}
			});
		},
		onClickCategoryRow(e) {
			console.log(e);
			this.popupIsOpen = true;
			this.catForPopup = e;
		},
		showCreatePopUp() {
			this.createPopUpIsOpen = !this.createPopUpIsOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollme {
	overflow-x: auto;
}
</style>
